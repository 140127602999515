import React from "react";
import ReactDOM from "react-dom/client";
import { mainnet, goerli } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { createClient, WagmiConfig, configureChains } from "wagmi";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";

import Home from "./Home";
import AlertProvider from "./AlertProvider";
import "./index.css";

const chosenProvider = alchemyProvider({
  apiKey: process.env.REACT_APP_CLIENT_RPC!,
});

console.log("NETWORK", process.env.REACT_APP_ETH_NETWORK!);

const { chains, provider } =
  process.env.REACT_APP_ETH_NETWORK! === "goerli"
    ? configureChains([goerli], [publicProvider()])
    : configureChains([mainnet], [chosenProvider]);
const { connectors } = getDefaultWallets({ appName: "DIX", chains });
const wagmiClient = createClient({ autoConnect: true, connectors, provider });

const root = ReactDOM.createRoot(document.getElementById("root") as any);

root.render(
  <React.StrictMode>
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains}>
        <AlertProvider>
          <Home />
        </AlertProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);
