import React from "react";
import {
  Provider as ReactAlertProvider,
  transitions,
  positions,
} from "react-alert";

export const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: "30px",
  transition: transitions.SCALE,
  containerStyle: {
    background: "rgba(0, 0, 0, 0.5)",
    width: "100vw",
    height: "100vh",
  },
};

export function AlertModal(args: any) {
  const { message } = args;
  return (
    <div style={{ fontSize: "2rem", color: "var(--color-fg)" }}>{message}</div>
  );
}

export default function AlertProvider({
  children,
}: {
  children: React.ReactElement;
}) {
  return (
    <ReactAlertProvider template={AlertModal} {...options}>
      {children}
    </ReactAlertProvider>
  );
}
