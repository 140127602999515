import { useSigner, useContract } from "wagmi";

import DIX_MINT_ABI from "./dixMintAbi.json";

export { DIX_MINT_ABI };

export const DIX_MINT_CONTRACT_ADDRESS =
  process.env.REACT_APP_ETH_NETWORK === "goerli"
    ? process.env.REACT_APP_DIX_MINT_CONTRACT_ADDRESS_GOERLI!
    : process.env.REACT_APP_DIX_MINT_CONTRACT_ADDRESS!;

console.log("DIX_MINT_CONTRACT_ADDRESS", DIX_MINT_CONTRACT_ADDRESS);

export function useDixMintContract() {
  const { data: signer } = useSigner();
  const contract = useContract({
    address: DIX_MINT_CONTRACT_ADDRESS,
    abi: DIX_MINT_ABI,
    signerOrProvider: signer,
  });
  return contract;
}
