import React, { useState, useCallback } from "react";
import { useAlert } from "react-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { useAccount } from "wagmi";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import { ethers } from "ethers";

import ConnectButton from "./ConnectButton";
import styles from "./Home.module.css";
import { useIsMounted } from "./useIsMounted";
import { DIX_MINT_CONTRACT_ADDRESS, useDixMintContract } from "./ethHooks";

const NFT_COST = process.env.REACT_APP_NFT_COST!;

export default function Home() {
  const [showConfetti, setShowConfetti] = useState<boolean>(false);
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);
  const alert = useAlert();
  const { address } = useAccount();
  const contract = useDixMintContract();
  const { width, height } = useWindowSize();

  const handleSuccessMessage = useCallback(() => {
    alert.show("success");
    setShowConfetti(true);
    setTimeout(() => {
      setShowConfetti(false);
    }, 5000);
  }, [alert]);

  const handleMint = useCallback(async () => {
    try {
      setLoading(true);
      console.log("address", address);
      console.log("NFT_COST", NFT_COST);
      console.log("DIX_MINT_CONTRACT_ADDRESS", DIX_MINT_CONTRACT_ADDRESS);

      if (!contract) throw Error("contract not connected");
      const ethValue = ethers.utils.parseEther(NFT_COST.toString()).mul(count);
      console.log("ethValue", ethValue);
      let nftTxn = await contract.mint(address, count, { value: ethValue });
      console.log("nftTxn.wait");

      await nftTxn.wait();
      console.log(nftTxn);
      console.log("nftTxn.hash", nftTxn?.hash);

      handleSuccessMessage();

      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.log(error);
      alert.show("fail");
    }
  }, [address, alert, contract, count, handleSuccessMessage]);

  return (
    <>
      <div className={styles.container}>
        <img src="/logo.png" alt="logo" className={styles.logo} />

        <div className={styles.quantitySelector}>
          <button
            className={styles.buttonSmall}
            onClick={() => setCount((state) => (state - 1 < 1 ? 1 : state - 1))}
          >
            <FontAwesomeIcon icon={faMinus} size="sm" style={{ width: 15 }} />
          </button>{" "}
          {count}{" "}
          <button
            className={styles.buttonSmall}
            onClick={() =>
              setCount((state) => (state + 1 > 2222 ? 2222 : state + 1))
            }
          >
            <FontAwesomeIcon icon={faPlus} size="sm" style={{ width: 15 }} />
          </button>
        </div>

        <div className={styles.actions}>
          {!address && !!isMounted && <ConnectButton />}
          {!!address && !!isMounted && !!loading && (
            <button disabled={true}>MINTING...</button>
          )}
          {!!address && !!isMounted && !loading && (
            <button onClick={handleMint} disabled={!!loading}>
              MINT
            </button>
          )}
        </div>
      </div>

      {!!isMounted && !!showConfetti && (
        <Confetti width={width} height={height} />
      )}
    </>
  );
}
